import React, {useContext, useState} from 'react';
import {AuthContext} from '../../auth';
import {Trans, useTranslation} from 'react-i18next';
import Link from '../../../components/link';
import serviceLinks from '../../../utils/config/serviceLinks';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import './MobileBusinessMenus.css';
import webUtils from '../../../utils/webUtils';
import pathUtils from '../../../utils/pathUtils';
import GoogleSearch from '../googlesearch';
import qr from 'query-string';
import SmbMenus from '../subheader/smbMenus';
import headerUtils from '../../../utils/headerUtils';
import productLinks from '../../../utils/config/productLinks';
import ExpandableList from './component/expandablelist';
import Resources from '../subheader/Resources';
import Menus from '../subheader/Menus';

export default function MobileBusinessMenus({setPopupMenu, setIndex, index}) {
  const {i18n} = useTranslation();
  const {location} = useReactRouter();
  const {isSignIn} = useContext(AuthContext);
  let services = [
    serviceLinks.youcam4web,
    {...serviceLinks.sdk, showDivider: true},
    {...serviceLinks.instoreconsultation, showDivider: true},
    {...serviceLinks.applisting, showDivider: true},
    serviceLinks.youcam4google,
    serviceLinks.virtualmakeupsnapchat,
    {...serviceLinks.virtualmakeupinstagram, showDivider: true},
    serviceLinks.wechatminiprogram,
    serviceLinks.taobaominiprogram,
    // serviceLinks.facebookarchatbot,
    serviceLinks.douyinminiprogram,
  ];
  const technologies = headerUtils.techMenu.filter(r => r);
  if (webUtils.isChs(i18n.language)) {
    services.splice(2, 1);
  }

  const goToDemostore = () => {
    const {functionType} = qr.parse(window.location.search);
    let url = '/business/showcase/lip-color';
    if (window.location.pathname.indexOf('/business/plan') !== -1) {
      url =
        functionType === 'EYEWEAR'
          ? '/business/showcase/online-service/eye-wear'
          : '/business/showcase/online-service/';
    }
    window.location.href = pathUtils.addLangPrefix(url);
  };
  const closeMenu = () => {
    setIndex('');
    setPopupMenu(false);
  };
  const isActive = path => location.pathname === pathUtils.addLangPrefix(path);
  return (
    <div className="pf-header-mobile-business-menus">
      <div className="pf-header-mobile-business-menus__item">
        <GoogleSearch is2b={true} />
      </div>
      <ExpandableList
        closeMenu={closeMenu}
        title={
          <Trans i18nKey="business:c.l.s.popupmenu.enterprise">
            Enterprise
          </Trans>
        }
        menus={<Menus />}
        setIndex={setIndex}
        index={index}
        id={'service'}
      />
      <ExpandableList
        closeMenu={closeMenu}
        title={
          <Trans i18nKey="business:c.l.s.leftmenu.online.service">
            Online Service
          </Trans>
        }
        menus={<SmbMenus />}
        setIndex={setIndex}
        index={index}
        id={'smb'}
      />
      <ExpandableList
        list={technologies}
        closeMenu={closeMenu}
        title={
          <Trans i18nKey="consumer:u.c.technologylinks.home">
            Technologies
          </Trans>
        }
        setIndex={setIndex}
        index={index}
        id={'tech'}
      />
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--active': isActive(
            '/business/showcase'
          ),
        })}
        onClick={goToDemostore}
      >
        <Trans i18nKey="business:c.l.s.leftmenu.demo.store">Demo Store</Trans>
      </div>
      <ExpandableList
        title={
          <Trans i18nKey="business:c.l.s.leftmenu.demo.resources">
            Resources
          </Trans>
        }
        menus={<Resources />}
        closeMenu={closeMenu}
        setIndex={setIndex}
        index={index}
        id={'resource'}
      />
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--active': isActive(
            '/business/ai-apis'
          ),
        })}
      >
        <Link isYce={true} yceKeyword={'ai-api'} onClick={closeMenu}>
          {productLinks.aiapi.text}
        </Link>
      </div>
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--active': isActive(
            productLinks.blog.link
          ),
        })}
      >
        <Link to={productLinks.blog.link} onClick={closeMenu}>
          {productLinks.blog.text}
        </Link>
      </div>
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--active': isActive(
            '/business/investors'
          ),
        })}
      >
        <Link to="https://ir.perfectcorp.com/" onClick={closeMenu}>
          <Trans i18nKey="business:c.l.s.rightmenu.investors">Investors</Trans>
        </Link>
      </div>
      {!isSignIn && (
        <div
          className={classnames({
            'pf-header-mobile-business-menus__item': true,
            'pf-header-mobile-business-menus__item--active': isActive(
              '/business/user/sign-in'
            ),
          })}
        >
          <Link to="/business/user/sign-in" onClick={closeMenu}>
            <Trans i18nKey="business:c.l.s.rightmenu.sign.in">Sign In</Trans>
          </Link>
        </div>
      )}
    </div>
  );
}
