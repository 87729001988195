import React from 'react';
import index from './index';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/business/user/home"
    exact
    path="/business/user/home"
    component={index}
    showDemoStore={false}
    showContactSales={false}
  />,
];
