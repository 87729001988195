import cookieUtils from './cookieUtils';
import users from './config/user';
import webUtils from './webUtils';

const utils = {
  getSignInUrl: (functionType, solutionType, subscriptionType, email) => {
    return `/business/user/sign-in?functionType=${functionType}&solutionType=${solutionType}&subscriptionType=${subscriptionType}&email=${email}`;
  },
  getAccountSettingUrl: () =>
    webUtils.getSMBEnvReactAppUrl({url: '/general-settings/account-settings'}),
  getAffiliationDays: ({solutionType = 'MAKEUP'}) => {
    const affiliationDays = cookieUtils.getCookie('affiliationDays');
    const isSkinCare =
      solutionType === 'SKIN_ANALYSIS' ||
      solutionType === 'AESTHETIC_SIMULATION';
    const parsedAffiliationDays = JSON.parse(affiliationDays || '{}');
    if (!parsedAffiliationDays[solutionType]) {
      return isSkinCare ? 14 : users.freeTrialDays;
    }
    return parsedAffiliationDays[solutionType];
  },
  getUserHomeUrl: (email, lang) => {
    if (utils.isTSAccount(email)) {
      return webUtils.getSMBEnvReactAppUrl({});
    }
    return `${webUtils.getLangPath(lang)}/business/user/home`;
  },
  isTSAccount: (email = '') => {
    if (!email) {
      return false;
    }
    return email.indexOf('@ts.perfectcorp.com') !== -1;
  },
};

export default utils;
