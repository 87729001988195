import React, {useState, useEffect, useRef} from 'react';
import {Route} from 'react-router';
import {useTranslation} from 'react-i18next';
import Footer4BusinessAsync from './footer4business/async';
import webUtils from '../../utils/webUtils';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';
import MenuAsync from '../../pages/business/showcase/components/menu/async';
import FadeTransition from '../transition/fade';
import _forEach from 'lodash/forEach';
import useSearchParams from '../../pages/business/showcase/components/hooks/useSearchParams';
import useCampaignSearchParams from '../../pages/business/showcase/components/menu/campaignmenuitems/useCampaignSearchParams';
import GoContactButton from '../floatingbtns/gotocontactbtn';
import FloatingBtns from '../floatingbtns';

export default ({component: Component, isEnterprise = false, ...rest}) => {
  const {i18n} = useTranslation();
  const [i18nReady, setI18nReady] = useState(false);
  const {closeWindowOnModuleClosed} = useSearchParams();
  const {isCampaignMode, campaignMenuItems} = useCampaignSearchParams();
  const loadedRef = useRef(false);
  const timeoutRef = useRef(0);
  const listenersRef = useRef([]);

  const shouldLoadWebModule = (pathname = '') => {
    return [
      '/business/showcase/youcam-tutorial',
      '/business/showcase/allure-november-look', // tutorial special link for Allure
      '/business/showcase/nail-color',
      '/business/showcase/beard-dye',
      '/business/showcase/beard-style',
      '/business/showcase/skincare/home', // for skincare widget
      '/business/showcase/skincare/diagnostics',
      '/business/showcase/skincare/hd-diagnostics',
      '/business/showcase/skincare/live-diagnostics',
      '/business/showcase/skincare/emulation',
      '/business/showcase/shadefinder/finder',
      '/business/showcase/shadefinder/debug',
      '/business/showcase/face-analyzer',
      '/business/showcase/face-reshaping',
      '/business/showcase/watches',
      '/business/showcase/bracelets',
      '/business/showcase/rings',
      '/business/showcase/personality',
      '/business/showcase/virtual-hairstyles',
      'business/showcase/hair-type',
      'business/showcase/hair-length',
      '/business/showcase/hair-frizziness',
      '/business/showcase/necklace',
      '/business/showcase/earrings',
      '/business/showcase/set',
    ].some(route => pathname.match(route));
  };

  useEffect(() => {
    i18n.on('initialized', () => setI18nReady(true));
  }, [i18n]);

  const removeEventListeners = () => {
    _forEach(
      listenersRef.current,
      l => window.YMK && window.YMK.removeEventListener(l)
    );
    listenersRef.current.length = 0;
  };

  const addEventListener = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (!window.YMK) {
        addEventListener();
        return;
      }
      removeEventListeners();
      listenersRef.current.push(
        window.YMK.addEventListener('closed', () => {
          if (closeWindowOnModuleClosed) {
            window.close();
          }
        })
      );
    }, 500);
  };

  useEffect(() => {
    if (!shouldLoadWebModule(rest.location.pathname) || loadedRef.current) {
      return;
    }
    loadedRef.current = true;
    webUtils.loadWebModule().then(() => addEventListener());

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      removeEventListeners();
    };
  }, []);

  return (
    <Route
      {...rest}
      render={matchProps => (
        <FadeTransition
          headerStyle={
            isCampaignMode
              ? {display: 'none'}
              : {position: webUtils.isMobile() ? 'relative' : 'sticky'}
          }
        >
          <MenuAsync key={i18nReady} displayMenuItems={campaignMenuItems} />
          <FloatingBtns
            showContactSales={true}
            scrollTopThreshold={220}
            displayNoneOnHidden={true}
          />
          <LazyLoad height={1000} once={true} unmountIfInvisible={true}>
            <div
              style={{backgroundColor: '#fff'}}
              className={classnames({
                'pf-language': true,
                'pf-language-ja': i18n.language === 'ja',
              })}
            >
              <Component {...matchProps} />
            </div>
          </LazyLoad>
          {!isCampaignMode && (
            <LazyLoad
              height={500}
              once={true}
              unmountIfInvisible={true}
              throttle={100}
            >
              <Footer4BusinessAsync pageReady={true} />
            </LazyLoad>
          )}
        </FadeTransition>
      )}
    />
  );
};
