import React from 'react';
import AsyncPage from './async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/features/face-editor"
    exact
    path="/consumer/features/face-editor"
    component={AsyncPage}
  />,
];
