import {Trans} from 'react-i18next';
import React from 'react';

const links = {
  airemoval: {
    id: 'airemoval',
    text: <Trans i18nKey="consumer:u.c.leftmenu.ai.removal">AI Removal</Trans>,
    link: '/consumer/features/remove-object-from-photo',
  },
  aifaceswap: {
    id: 'aifaceswap',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.face.swap">AI Face Swap</Trans>
    ),
    link: '/consumer/features/ai-face-swap',
  },
  photocollage: {
    id: 'photocollage',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.photo.collage">Photo Collage</Trans>
    ),
    link: '/consumer/features/photo-collage-maker',
  },
  aiphotoenhancer: {
    id: 'aiphotoenhancer',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.photo.enhancer">
        AI Photo Enhancer
      </Trans>
    ),
    link: '/consumer/features/ai-photo-enhancer',
  },
  backgroundremoval: {
    id: 'backgroundremoval',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.background.removal">
        Background Removal
      </Trans>
    ),
    link: '/consumer/features/remove-background-from-image',
  },
  faceeditor: {
    id: 'faceeditor',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.face.editor">Face Editor</Trans>
    ),
    link: '/consumer/features/face-editor',
  },
  makeupeditor: {
    id: 'makeupeditor',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.makeup.editor">Makeup Editor</Trans>
    ),
    link: '/consumer/features/makeup-editor',
  },
  teethwhitener: {
    id: 'teethwhitener',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.teeth.whitener">
        Teeth Whitener
      </Trans>
    ),
    link: '/consumer/features/teeth-whitening',
  },
  bodyeditor: {
    id: 'bodyeditor',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.body.editor">Body Editor</Trans>
    ),
    link: '/consumer/features/body-editor',
  },
  haircolorchanger: {
    id: 'haircolorchanger',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.hair.color.changer">
        Hair Color Changer
      </Trans>
    ),
    link: '/consumer/features/hair-color-changer',
  },
  videobodyeditor: {
    id: 'videobodyeditor',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.body.editor">
        Video Body Editor
      </Trans>
    ),
    link: '/consumer/features/video-body-editor',
  },
  videoskinretouch: {
    id: 'videoskinretouch',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.skin.retouch">
        Video Skin Retouch
      </Trans>
    ),
    link: '/consumer/features/video-skin-retouch',
  },
  aivideoenhance: {
    id: 'aivideoenhance',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.video.enhance">
        AI Video Enhance
      </Trans>
    ),
    link: '/consumer/features/ai-video-enhance',
  },
  videofaceswap: {
    id: 'videofaceswap',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.face.swap">
        Video Face Swap
      </Trans>
    ),
    link: '/consumer/features/video-face-swap',
  },
  imagetovideo: {
    id: 'imagetovideo',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.image.to.video">
        Image to Video
      </Trans>
    ),
    link: '/consumer/features/image-to-video-ai',
  },
  addtexttophoto: {
    id: 'addtexttophoto',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.add.text.to.photo">
        Add Text to Photo
      </Trans>
    ),
    link: '/consumer/features/add-text-to-photo',
  },
  texttoimage: {
    id: 'texttoimage',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.text.to.image">Text to Image</Trans>
    ),
    link: '/consumer/features/text-to-image-ai',
  },
  photofeffects: {
    id: 'photofeffects',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.photo.effects">
        AI Photo Effects
      </Trans>
    ),
    link: '/consumer/features/photo-effects',
  },
  blurphoto: {
    id: 'blurphoto',
    text: <Trans i18nKey="consumer:u.c.leftmenu.blur.photo">Blur Photo</Trans>,
    link: '/consumer/features/blur-images',
  },
  cutout: {
    id: 'cutout',
    text: <Trans i18nKey="consumer:u.c.leftmenu.cutout">AI Cutout</Trans>,
    link: '/consumer/features/cutout-images',
  },
  skinsmoother: {
    id: 'skinsmoother',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.skin.smoother">Skin Smoother</Trans>
    ),
    link: '/consumer/features/skin-smoother',
  },
  adjustheight: {
    id: 'adjustheight',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.adjust.height">Adjust Height</Trans>
    ),
    link: '/consumer/features/make-legs-longer',
  },
  eyebagremover: {
    id: 'eyebagremover',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.eye.bag.remover">
        Eye Bag Remover
      </Trans>
    ),
    link: '/consumer/features/eye-bag-remover',
  },
  doublechinremover: {
    id: 'doublechinremover',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.double.chin.remover">
        Double Chin Remover
      </Trans>
    ),
    link: '/consumer/features/double-chin-remover',
  },
  skintonechanger: {
    id: 'skintonechanger',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.skin.tone.changer">
        Skin Tone Changer
      </Trans>
    ),
    link: '/consumer/features/skin-tone-changer',
  },
  videofacereshape: {
    id: 'videofacereshape',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.face.reshape">
        Video Face Reshape
      </Trans>
    ),
    link: '/consumer/features/reshape-face-in-video',
  },
  videohaircolorchanger: {
    id: 'videohaircolorchanger',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.hair.color.changer">
        Video Hair Color Changer
      </Trans>
    ),
    link: '/consumer/features/video-hair-color-changer',
  },
  videostyletransfer: {
    id: 'videostyletransfer',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.style.transfer">
        Video Style Transfer
      </Trans>
    ),
    link: '/consumer/features/video-style-transfer',
  },
  videobgremoval: {
    id: 'videobgremoval',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.background.removal">
        Video Background Removal
      </Trans>
    ),
    link: '/consumer/features/video-background-removal',
  },
  videofaceblur: {
    id: 'videofaceblur',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.video.face.blur">
        Video Face Blur
      </Trans>
    ),
    link: '/consumer/features/video-face-blur',
  },
};

export default links;
