import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade} from 'swiper';
import Link from '../../../link';
import _get from 'lodash/get';
import React from 'react';
import 'swiper/swiper.min.css';
import './index.css';

export default function SlideBanner({data, width}) {
  const path =
    width > 600
      ? 'imageDesktop.data.attributes.url'
      : 'imageMobile.data.attributes.url';
  return (
    <Swiper
      centeredSlides={true}
      loop={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: true,
      }}
      modules={[Autoplay, EffectFade]}
      className="pf-btf-rotate-banner"
    >
      {data.map((s, i) => (
        <SwiperSlide key={`home-banner${i}`}>
          {_get(s, 'ctaLink') ? (
            <Link to={_get(s, 'ctaLink')} isNewTab={_get(s, 'newTab')}>
              {_get(s, path) && <img alt="" src={_get(s, path)} />}
            </Link>
          ) : (
            _get(s, path) && <img alt="" src={_get(s, path)} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
