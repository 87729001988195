import classnames from 'classnames';
import React from 'react';

export default function ArrowIcon({isShow, color, style = {}}) {
  if (color === 'grey' && !isShow) {
    return (
      <div
        className=" pf-header-mobile-business-menus__arrow grey"
        style={style}
      />
    );
  }
  return (
    <div
      className={classnames({
        'pf-header-mobile-business-menus__arrow': true,
        up: isShow,
        down: !isShow,
      })}
      style={style}
    />
  );
}
