import React from 'react';
import PinkButton from '../button/pinkbutton';
import ArrowLink from '../button/arrowlink';
import IosBtn from '../downloadbutton/iosbtn';
import AndroidBtn from '../downloadbutton/androidbtn';
import webUtils from '../../utils/webUtils';
import Link from '../link';
import useWindowWidth from '../hooks/useWindowWidth';
import './buttontype.css';

export default function ButtonType({
  type = '',
  link = '',
  text = '',
  icon = '',
  newTab,
  nofollow,
  index = 0,
}) {
  const width = useWindowWidth();
  const iosbtn = IosBtn();
  const androidbtn = AndroidBtn();
  const isAndroid = webUtils.isAndroid();
  if (type === 'White button') {
    return (
      <PinkButton
        className="pf-full-btn--mb"
        bgColor={'white'}
        text={text}
        link={link}
        square={true}
        isNewTab={newTab}
        rel={nofollow ? 'nofollow noopener noreferrer' : ''}
        style={{padding: '0 40px'}}
      />
    );
  }

  if (type === 'Pink button') {
    return (
      <PinkButton
        className="pf-full-btn--mb"
        bgColor={'pink'}
        text={text}
        link={link}
        square={true}
        isNewTab={newTab}
        rel={nofollow ? 'nofollow noopener noreferrer' : ''}
        style={{padding: '0 40px'}}
      />
    );
  }

  if (type === 'Text with arrow icon') {
    return (
      <ArrowLink
        newTab={newTab}
        link={link}
        title={text}
        rel={nofollow ? 'nofollow noopener noreferrer' : ''}
      />
    );
  }

  if (type === 'Round pink button') {
    return (
      <PinkButton
        className="pink-button--round"
        text={text}
        link={link}
        square={false}
        isNewTab={newTab}
        rel={nofollow ? 'nofollow noopener noreferrer' : ''}
      />
    );
  }

  if (type === 'Text with arrow right circle') {
    return (
      <ArrowLink
        link={link}
        title={text}
        newTab={newTab}
        iconStyle={'circle'}
        rel={nofollow ? 'nofollow noopener noreferrer' : ''}
      />
    );
  }

  if (type === 'Pink button with icon') {
    return (
      <PinkButton
        isNewTab={newTab}
        className="pink-button--icon"
        text={
          <div>
            {icon && <img height={28} alt={text} src={icon} />}
            <div>{text}</div>
          </div>
        }
        link={link}
        rel={nofollow ? 'nofollow noopener noreferrer' : ''}
      />
    );
  }

  if (type === 'App download button') {
    const isAppleLink = link.indexOf('apple.com') !== -1;
    const isGoogleLink =
      link.indexOf('google.com') !== -1 || link.indexOf('android') !== -1;
    const isAppDownloadLinks = !isAppleLink && !isGoogleLink;
    const googleDownloadButton = (
      <Link to={link} className="promo-card--download-btn">
        <img className="app-btn" src={androidbtn} alt="download app" />
      </Link>
    );
    const appleDownloadButton = (
      <Link
        to={link}
        className="promo-card--download-btn"
        isNewTab={webUtils.isSafari()}
      >
        <img src={iosbtn} alt="download app" className="app-btn" />
      </Link>
    );
    if (
      (width > 1024 && (isAppleLink || (isAppDownloadLinks && index === 0))) ||
      (width <= 1024 &&
        !isAndroid &&
        (isAppleLink || (isAppDownloadLinks && index === 0)))
    ) {
      return appleDownloadButton;
    }

    if (
      (width > 1024 && (isGoogleLink || (isAppDownloadLinks && index === 1))) ||
      (width <= 1024 &&
        isAndroid &&
        (isGoogleLink || (isAppDownloadLinks && index === 1)))
    ) {
      return googleDownloadButton;
    }

    return <div />;
  }

  return (
    <PinkButton
      className="pf-full-btn--mb"
      text={text}
      link={link}
      square={true}
      isNewTab={newTab}
      rel={nofollow ? 'nofollow noopener noreferrer' : ''}
    />
  );
}
