import React from 'react';
import {convertNodeToElement} from 'react-html-parser';
import _set from 'lodash/set';
import PinkButton from '../button/pinkbutton';
import _get from 'lodash/get';
import Link from '../link';
import webUtils from '../../utils/webUtils';
import ArrowLink from '../button/arrowlink';
import IosBtn from '../downloadbutton/iosbtn';
import AndroidBtn from '../downloadbutton/androidbtn';

const utils = {
  transformDescContent: (node, index) => {
    if (node.name === 'input') {
      _set(node, 'attribs.style', 'display:none;');
    }
    if (node.name === 'table') {
      _set(
        node,
        'attribs.style',
        `width: 100%;table-layout: fixed;margin-bottom:0;`
      );
    }
    if (node.name === 'td' || node.name === 'th') {
      _set(node, 'attribs.style', `border: none; padding: 0;`);
    }
    if (node.name === 'ul') {
      _set(node, 'attribs.style', `margin-bottom: 0`);
    }
    return convertNodeToElement(node, index, utils.transformDescContent);
  },
  buttonType: (data, index, width) => {
    const iosbtn = IosBtn();
    const androidbtn = AndroidBtn();
    const isAndroid = webUtils.isAndroid();
    if (data.buttonType === 'Pink button') {
      return (
        <div key={`promo-card-cta${index}`}>
          <PinkButton
            text={data.ctaText}
            link={data.ctaLink}
            isNewTab={data.newTab}
            square={true}
            className="promo-card-cta"
            gtm={data.gtm}
          />
        </div>
      );
    }

    if (data.buttonType === 'App download button' && _get(data, 'ctaLink')) {
      const isAppleLink = data.ctaLink.indexOf('apple.com') !== -1;
      const isGoogleLink = data.ctaLink.indexOf('google.com') !== -1;
      const isAppDownloadLinks = !isAppleLink && !isGoogleLink;
      const googleDownloadButton = (
        <Link
          to={data.ctaLink}
          className="promo-card--download-btn"
          key={`promo-card-cta${index}`}
        >
          <img className="app-btn" src={androidbtn} alt="download app" />
        </Link>
      );
      const appleDownloadButton = (
        <Link
          to={data.ctaLink}
          className="promo-card--download-btn"
          key={`promo-card-cta${index}`}
          isNewTab={webUtils.isSafari()}
        >
          <img src={iosbtn} alt="download app" className="app-btn" />
        </Link>
      );
      if (
        (width > 1024 &&
          (isAppleLink || (isAppDownloadLinks && index === 0))) ||
        (width <= 1024 &&
          !isAndroid &&
          (isAppleLink || (isAppDownloadLinks && index === 0)))
      ) {
        return appleDownloadButton;
      }

      if (
        (width > 1024 &&
          (isGoogleLink || (isAppDownloadLinks && index === 1))) ||
        (width <= 1024 &&
          isAndroid &&
          (isGoogleLink || (isAppDownloadLinks && index === 1)))
      ) {
        return googleDownloadButton;
      }

      return <div />;
    }

    return (
      <div key={`promo-card-cta${index}`}>
        <ArrowLink
          newTab={data.newTab}
          link={data.ctaLink}
          title={data.ctaText}
          gtm={data.gtm}
        />
      </div>
    );
  },
};

export default utils;
