import React, {useEffect, useState} from 'react';
import useWindowScroll from '../hooks/useWindowScroll';
import _defer from 'lodash/defer';
import classnames from 'classnames';
import GoContactButton from './gotocontactbtn';
import './index.css';
import GoDemoButton from './godemobtn';
import GotoFreeTrial from './gotofreetrial';
import OnlineServiceButton from '../onlineservicebutton';
import webUtils from '../../utils/webUtils';

export default function FloatingBtns({
  showContactSales = false,
  showDemoStore = false,
  showFreeTrial = false,
  showOnlineService = false,
  scrollTopThreshold = 0,
  displayNoneOnHidden = false,
}) {
  const scrollTop = useWindowScroll();
  const [enabled, setEnabled] = useState(false);
  const [isVisible, setIsVisible] = useState(enabled);

  const checkVisibilityIfNeeded = e => {
    if (!displayNoneOnHidden) {
      return;
    }
    const computedStyle = e.target && window.getComputedStyle(e.target);
    if (
      computedStyle &&
      (computedStyle.getPropertyValue('opacity') === '0' ||
        computedStyle.getPropertyValue('visibility') === 'hidden' ||
        computedStyle.getPropertyValue('display') === 'none')
    ) {
      setIsVisible(false);
    }
  };

  const getStyle = () =>
    displayNoneOnHidden && !isVisible ? {display: 'none'} : {};

  useEffect(() => {
    const enabled = scrollTop > scrollTopThreshold;
    _defer(() => {
      setEnabled(enabled);
      enabled && setIsVisible(true);
    });
  }, [scrollTop, scrollTopThreshold]);

  return (
    <div
      className={classnames({
        'floating-buttons-container': true,
        'floating-buttons-container--active': enabled,
        'floating-buttons-container--inactive': !enabled,
      })}
      id={'floatingbuttons'}
      onTransitionEnd={checkVisibilityIfNeeded}
      style={getStyle()}
    >
      {showContactSales && webUtils.isBusiness() && <GoContactButton />}
      {showDemoStore && webUtils.isBusiness() && <GoDemoButton />}
      {showFreeTrial && webUtils.isBusiness() && <GotoFreeTrial />}
      {showOnlineService && webUtils.isBusiness() && <OnlineServiceButton />}
    </div>
  );
}
