import ymkConfigs from './config/ymk';
import webUtils from './webUtils';
import shareUtils from './shareUtils';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _some from 'lodash/some';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import _isString from 'lodash/isString';
import fetch from 'isomorphic-fetch';
import {searchParamKeys} from '../pages/business/showcase/components/hooks/useSearchParams';
import _startsWith from 'lodash/startsWith';
import _map from 'lodash/map';

export const makeuproutes = [
  'look',
  'lip-color',
  'lip-plumper',
  'lip-liner',
  'blush',
  'bronzer',
  'foundation',
  'concealer',
  'contour',
  'highlighter',
  'eyebrow',
  'eye-liner',
  'eye-shadow',
  'eyelashes',
  'eye-color',
];
export const threedtypes = {
  watch: 'watch',
  ring: 'ring',
};
export const demostoreStrapiPage = {
  emulation: 'emulation',
  skincare: 'skincare',
  earrings2d: 'earrings2d',
  watch2d: 'watch2d',
  bracelet2d: 'bracelet2d',
  ring2d: 'ring2d',
  necklace2d: 'necklace2d',
};
export const skintypes = {
  unknown: 'Unknown',
  normal: 'Normal',
  dry: 'Dry',
  oily: 'Oily',
  combination: 'Combination',
  normalSensitive: 'Normal & Sensitive',
  drySensitive: 'Dry & Sensitive',
  oilySensitive: 'Oily & Sensitive',
  combinationSensitive: 'Combination & Sensitive',
};
export const skintypeZones = {
  full: 'default',
  tzone: 't_zone',
  uzone: 'u_zone',
};
export const skintypeAspects = {
  dryness: 'dryness',
  oiliness: 'oiliness',
  redness: 'redness',
};
export const wcmApiPrefix = 'https://plugins.makeupar.com/api/webconsultation';

const utils = {
  isDemostore: (path = window.location.pathname) =>
    _includes(path, '/business/showcase'),
  isActiveType: (key = '', path = '') => {
    if (
      key === 'youcam-tutorial' &&
      window.location.pathname.match('/business/showcase/allure-november-look') // tutorial special link for Allure
    ) {
      return true;
    }
    if (utils.isEmulation()) {
      return key === 'emulation';
    }
    return (
      (path && !!window.location.pathname.match(path)) ||
      (key &&
        !!window.location.pathname.match(
          new RegExp(`/${key}(/[a-zA-Z-]*)?$`, 'g')
        ))
    );
  },
  isSkincareWidget: () =>
    !!window.location.pathname.match('/business/showcase/skincare/home'),
  isEmulation: () =>
    !!window.location.pathname.match('/business/showcase/skincare/emulation') ||
    !!window.location.pathname.match('/business/showcase/emulation/home'),
  isBeardDye: () => utils.isActiveType('beard-dye'),
  isBeardStyle: () => utils.isActiveType('beard-style'),
  isTutorial: () => utils.isActiveType('youcam-tutorial'),
  isNail: () => utils.isActiveType('nail-color'),
  isWatch: () => utils.isActiveType('watches'),
  isBracelets: () => utils.isActiveType('bracelets'),
  isRings: () => utils.isActiveType('rings'),
  isFaceAnalyzer: () => utils.isActiveType('face-analyzer'),
  isPersonality: () => utils.isActiveType('personality'),
  isSkincare: () => utils.isActiveType('skincare'),
  isLiveDiagnostic: () =>
    utils.isActiveType(
      'live-diagnostic',
      '/business/showcase/skincare/live-diagnostics'
    ),
  isHdDiagnostic: () =>
    utils.isActiveType(
      'hd-diagnostic',
      '/business/showcase/skincare/hd-diagnostics'
    ),
  isEarring: () => utils.isActiveType('earrings'),
  isEyewear: () => utils.isActiveType('eye-wear'),
  isHairColor: () => utils.isActiveType('hair-color'),
  isHairStyle: () => utils.isActiveType('virtual-hairstyles'),
  isHairType: () => utils.isActiveType('hair-type'),
  isHairLength: () => utils.isActiveType('hair-length'),
  isHairFrizziness: () => utils.isActiveType('hair-frizziness'),
  isNecklace: () => utils.isActiveType('necklace'),
  isMakeup: () => makeuproutes.some(r => utils.isActiveType(r)),
  isHandAR: () =>
    utils.isNail() ||
    utils.isWatch() ||
    utils.isRings() ||
    utils.isBracelets() ||
    utils.isStacking() ||
    utils.is3DViewer(),
  isStacking: () => utils.isActiveType('set'),
  is3DViewer: () => utils.isActiveType('3d-viewer'),
  isNonMakeupLauncher: () =>
    ['eye-wear', 'hat', 'headband'].some(key => utils.isActiveType(key)),
  isSizerSupported: () =>
    webUtils.isMobile() &&
    (utils.isWatch() || utils.isBracelets() || utils.isRings()),
  getApiKey: () => {
    if (
      utils.isSkincareWidget() ||
      utils.isLiveDiagnostic() ||
      utils.isHdDiagnostic()
    ) {
      return 'dpVlbJeSVR8aUg8cwCA';
    }
    if (utils.isBeardDye()) {
      return 'for-demostore-beard-dye';
    }
    if (utils.isEarring()) {
      return 'x1r2CKNmcVJhyYGIJO0A';
    }
    if (utils.isHairColor()) {
      return 'znxO0fAdgxsUqt0jbiM2Kg';
    }
    if (utils.isNonMakeupLauncher()) {
      return 'for-demostore-eyewear';
    }
    if (utils.isTutorial()) {
      return 'SW1H0BokbuNBIEKsYNjgHQ';
    }
    if (utils.isFaceAnalyzer() || utils.isPersonality()) {
      return 'ER33JgfdB02arUkFlgqhA';
    }
    if (utils.isNail()) {
      return 'R8928ZcYxrd3pnt0GrWk0g';
    }
    if (utils.isWatch() || utils.isBracelets()) {
      // isolate wrist apiKey from hand AR (ring) for eBug:SMB230831-0001
      return 'for-demostore-wrist';
    }
    if (utils.isRings()) {
      // isolate ring apiKey from hand AR to avoid loading unnecessary resources (try to reduce crash rate)
      return 'for-demostore-ring';
    }
    if (utils.is3DViewer() || utils.isStacking()) {
      return 'for-demostore-handar';
    }
    if (utils.isHairStyle()) {
      return 'u4dvSF7LsQxgmiEwSK1OQ';
    }
    if (utils.isHairType()) {
      return '1axp5qbwqB1YdNnEVhPpaQ-hair-type';
    }
    if (utils.isHairLength()) {
      return '1axp5qbwqB1YdNnEVhPpaQ-hair-length';
    }
    if (utils.isHairFrizziness()) {
      return '1axp5qbwqB1YdNnEVhPpaQ-hair-frizziness';
    }
    if (utils.isNecklace()) {
      return 'CaKExW7N5Q0EMXeJJ0G4w';
    }

    return 'for-smb-adv-skincare';
  },
  getHandARModeBySkuType: type => {
    switch (type) {
      case threedtypes.watch:
        return ymkConfigs.handarMode.wrist;
      case threedtypes.ring:
      default:
        return ymkConfigs.handarMode.ring;
    }
  },
  getModuleSdkUrl: (inputApiKey, inputBuild) => {
    const apiKey = inputApiKey || utils.getApiKey();
    let build = 'v1.73.3-demostore';
    if (utils.isHdDiagnostic() || utils.isEmulation()) {
      build = 'v1.73-demostore-emulation'; // TODO: temp for feedback (should use generic once feedback has been added to generic)
    }
    if (utils.is3DViewer()) {
      build = 'v1.59.5-demostore';
    }
    if (utils.isSkincareWidget()) {
      build = 'v1.71-skincare-widget-demostore';
    }
    if (utils.isLiveDiagnostic()) {
      build = 'v1.71-demostore-live-skincare';
    }
    if (utils.isTutorial()) {
      build = 'v1.15-tutorial-demostore';
    }
    if (utils.isBeardStyle()) {
      build = 'v1.36-beard-style-demostore-2';
    }
    if (utils.isPersonality()) {
      build = 'v1.46-personality-demostore-3';
    }
    if (utils.isHairStyle()) {
      build = 'v1.4-server-demostore';
    }
    if (utils.isHairType()) {
      build = 'v1.8.5-server';
    }
    if (utils.isHairLength()) {
      build = 'v1.8.10-server';
    }
    if (utils.isHairFrizziness()) {
      build = 'v1.9-server';
    }
    if (inputBuild) {
      build = inputBuild;
    }

    const searchParamSdk = new URLSearchParams(window.location.search).get(
      searchParamKeys.sdk
    );
    if (searchParamSdk && _startsWith(searchParamSdk, 'http')) {
      return decodeURIComponent(searchParamSdk);
    }
    if (searchParamSdk) {
      build = searchParamSdk;
    }
    return webUtils.isChs(webUtils.getPathnameLang(window.location.pathname))
      ? `https://plugins-media.youcamapi.cn/${build}/sdk.js?apiKey=${apiKey}`
      : `//plugins-media.makeupar.com/${build}/sdk.js?apiKey=${apiKey}`;
  },
  getRequirementFeature: () => {
    if (utils.isStacking()) {
      return 'set';
    }
    if (utils.isHandAR() || utils.isNecklace()) {
      return 'handARAndNecklace';
    }
    if (utils.isSkincare()) {
      return 'skincare';
    }
    if (utils.isNecklace()) {
      return 'necklace';
    }
    if (utils.isMakeup()) {
      return 'makeup';
    }
    return 'vto';
  },
  getDefaultSkinSmooth: () => {
    if (utils.isHandAR()) {
      return 30;
    }
    if (utils.isSkincare() || utils.isEmulation()) {
      return 0;
    }
    return 50;
  },
  getModuleInitParams: () => ({
    debug: false,
    enableMouthTrackingRefinement: utils.isMakeup(),
    disableSkinSmooth: utils.isSkincare() || utils.isEmulation(),
    skinSmoothStrength: utils.getDefaultSkinSmooth(),
    skinSmoothColorIntensity: utils.getDefaultSkinSmooth(),
    language: utils.getModuleLanguage(),
    disable2ColorComparison: true,
    enableMobileZoomButton: utils.isMakeup(),
    hideFlipCameraButton:
      !utils.isBeardDye() &&
      !utils.isNail() &&
      !utils.isRings() &&
      !utils.isWatch() &&
      !utils.isBracelets() &&
      !utils.isStacking() &&
      !utils.isNecklace() &&
      !utils.is3DViewer() &&
      !utils.isEyewear() &&
      !utils.isHairLength(),
    forceShowFlipCameraButton: utils.is3DViewer(), // 3dviewer VTO module needs to show flip camera while hideButtonsOnResultPag=true
    disableEarringForFrontal: utils.isEarring(),
    enableAutoZoomButton: utils.isEarring(),
    intensitySliderDisplayMode: utils.isMakeup() ? 'enabled' : 'disabled',
    enableFaceAttributeSkinColorAppearance: utils.isFaceAnalyzer(),
    // enableWristAutoZoom: utils.isWatch() || utils.isBracelets(),
    disablePinchZoom: true,
    intensitySliderPosition: 'right',
    disableTrackingWhileApplying3DObjects: utils.isEyewear(),
    enableEyewearPDScaleMode: utils.isEyewear(),
    enableEyewearPD: utils.isEyewear(),
    enableEyewearPDMeasurementButton: utils.isEyewear(),
    enableRecordMode: utils.isEyewear(),
    disableNailsTakePhotoMode: utils.isNail(),
    hideTakeAPhotoIcon:
      utils.isWatch() ||
      utils.isBracelets() ||
      utils.isRings() ||
      utils.isStacking(),
    enableManualWristSizer: utils.isWatch() || utils.isBracelets(),
    enableManualRingSizer: utils.isRings(),
    enableSizeGuideEntry: utils.isRings() || utils.isBracelets(),
    disableDeviceWarningToast: true,
    enableSizerToast: utils.isRings() || utils.isBracelets(),
    enableSizeMapping: utils.isRings() || utils.isBracelets(),
    enableRingFingerTypeChange: utils.isRings(),
    enableRingFingerTypeChangeDummyButton: utils.isRings(),
    enableMultiFingerRingVto: utils.isRings() || utils.isStacking(),
    enableHandARObjectCache: utils.isBracelets(),
    cleanHandARObjectCacheBeforeApply: true,
    enableUltraWideCamera:
      utils.isRings() ||
      utils.isBracelets() ||
      utils.isWatch() ||
      utils.isStacking(),
    disableResolutionCheck: true,
    enableNecklaceUI: utils.isNecklace(),

    /* set to default value (to avoid it being overwritten after opening certain module) */
    skincareFaceSizeRatioThreshold: 0.55,
    zoomRatio: 1.0,
    rightButtonsBottomOffset: 0, // for 3D viewer VTO button
    bottomOffset: 0, // for hand AR sizer-VTO visible module size adjustment
    hideRightButtonsOnResultPage: false,
    hideButtonsOnResultPage: false,
    enableSkinTypeInfoButton: false,
    enableDummySizerEntry: false,
    enableSizeCircumferenceMapping: false,
    enableEmulationDiffImage: false,
    enableEmulationDisplayModeSwitchButton: false,
    disableEmulationDisplayModeAutoReset: false,
    enableSkincareResultZoomButton: false,
    showCompareCaption: false,
    defaultFacingMode: undefined, // for Ubitus virtual room to force-open front cam on hand AR modules
    enableBeardUI: false,
    enableHairTypeDetection: false,
    hd_skin_care_settings: {},
  }),
  getModuleSharingParams: () => {
    return {
      enableFBSharing: false,
      enableWeiboSharing: false,
      enableEmailSharing: false,
      enableTwitterSharing: false,
      enablePinterestSharing: false,
      enableSMSSharing: false,
      enableWhatsAppSharing: false,
    };
    const lang = webUtils.getPathnameLang(window.location.pathname);
    const isChs = webUtils.isChs(lang);
    const enableFBSharing = !isChs;
    if (enableFBSharing && !window.FB) {
      shareUtils.loadFbSdk();
      window.fbAsyncInit = shareUtils.initFbSdk;
    }
    return {
      enableFBSharing,
      enableWeiboSharing: isChs,
      enableEmailSharing: true,
      enableTwitterSharing: true,
      enablePinterestSharing: true,
      enableSMSSharing: true,
      enableWhatsAppSharing: true,
    };
  },
  getModuleLanguage: () => {
    const lang = webUtils.getPathnameLang(window.location.pathname);
    switch (webUtils.determineLanguage(lang)) {
      case 'zh_CN':
        return 'chs';
      case 'zh_TW':
        return 'cht';
      case 'ja':
        return 'jpn';
      case 'fr':
        return 'fra';
      case 'ko':
        return 'kor';
      case 'de':
        return 'deu';
      case 'es':
        return 'esp';
      case 'pt':
        return 'ptb';
      case 'it':
        return 'ita';
      case 'en':
      default:
        return 'enu';
    }
  },
  ymkOnceEventListener: (eventKey, listener) => {
    if (!window.YMK) {
      return;
    }
    const l = window.YMK.addEventListener(eventKey, () => {
      window.YMK && window.YMK.removeEventListener(l);
      listener && listener();
    });
  },
  ymkReset: productType =>
    window.YMK &&
    window.YMK.isLoaded() &&
    window.YMK.reset(utils.toResetType(productType)),
  ymkResetAllMakeupExceptBackground: () => {
    window.YMK &&
      window.YMK.isLoaded() &&
      ymkConfigs.makeupResetTypes.forEach(t => window.YMK.reset(t));
  },
  isLookPage: () => {
    const p = _get(window, 'location.pathname', '/');
    return p.indexOf('/business/showcase/look') !== -1;
  },
  productTypeToTitleName: productType => {
    switch (productType) {
      case 'lip-color':
        return 'Lipstick';
      case 'lip-liner':
        return 'Lip Liner';
      case 'eyebrow':
        return 'Eyebrow';
      case 'eye-color':
        return 'Contact Lens';
      case 'eyelashes':
        return 'Eyelashes';
      case 'eye-liner':
        return 'Eyeliner';
      case 'eye-shadow':
        return 'Eyeshadow';
      case 'blush':
        return 'Blush';
      case 'foundation':
        return 'Foundation';
      case 'concealer':
        return 'Concealer';
      case 'bronzer':
        return 'Bronzer';
      case 'contour':
        return 'Contour';
      case 'eye-wear':
        return 'Glasses';
      case 'hair-color':
        return 'Hair Color';
      default:
        return productType;
    }
  },
  toResetType: productType => {
    switch (productType) {
      case 'lip-color':
      case 'lip-plumper':
      case 'lipstick':
        return 'lipstick';
      case 'lip-liner':
      case 'lipliner':
        return 'lipliner';
      case 'eyebrow':
        return 'eyebrow';
      case 'eye-color':
      case 'eyecontact':
        return 'eyecontact';
      case 'eyelashes':
        return 'eyelash';
      case 'eye-liner':
      case 'eyeliner':
        return 'eyeliner';
      case 'eye-shadow':
      case 'eyeshadow':
        return 'eyeshadow';
      case 'blush':
        return 'blush';
      case 'foundation':
        return 'foundation';
      case 'concealer':
        return 'concealer';
      case 'bronzer':
        return 'bronzer';
      case 'contour':
      case 'face_contour':
        return 'face_contour';
      case 'background':
        return 'background';
      case 'face-reshaping':
      case 'facereshape':
        return 'facereshape';
      default:
        return;
    }
  },
  convertResetTypeToProductTypes: resetType => {
    switch (resetType) {
      case 'lipstick':
        return ['lip-color', 'lip-plumper'];
      case 'lipliner':
        return ['lip-liner'];
      case 'eyebrow':
        return ['eyebrow'];
      case 'eyecontact':
        return ['eye-color'];
      case 'eyelash':
        return ['eyelashes'];
      case 'eyeliner':
        return ['eye-liner'];
      case 'eyeshadow':
        return ['eye-shadow'];
      case 'blush':
        return ['blush'];
      case 'foundation':
        return ['foundation'];
      case 'concealer':
        return ['concealer'];
      case 'bronzer':
        return ['bronzer'];
      case 'face_contour':
        return ['contour'];
      case 'facereshape':
        return ['face-reshaping'];
      default:
        return [];
    }
  },
  isAutoApplyEnabled: productType =>
    ['eye-wear', 'lip-color', 'eye-liner'].includes(productType),
  isProductTypeReset: (resetType, productType) =>
    !resetType ||
    utils
      .convertResetTypeToProductTypes(resetType)
      .some(t => t === productType),
  getProductName: () => {
    const showcasePath = window.location.pathname.split('showcase/').pop();
    if (/\//.test(showcasePath)) {
      return showcasePath.split('/')[0];
    }
    return showcasePath;
  },
  hasWhite: color => {
    if (Array.isArray(color)) {
      return (
        !color.length || _some(color, c => !c || _toLower(c) === '#ffffff')
      );
    }
    return !color || _toLower(color) === '#ffffff';
  },
  getStrokeColor: (color, withStroke = false) => {
    if (withStroke || utils.hasWhite(color)) {
      return '#ddd';
    }
    return '#fff';
  },
  prefetch: urls =>
    _forEach(
      urls,
      url =>
        url &&
        _isString(url) &&
        !_includes(url, 'undefined') &&
        fetch(url, {method: 'GET'})
    ),
  convertSkinAnalysisResult: (result = {}) => {
    return {
      skinAge: result.skinAge,
      all: result.skinHealth,
      skinType: result.skinType,
      tZone: result.tZone,
      uZone: result.uZone,
      spots: result.ageSpots || result.spots,
      wrinkles: result.wrinkles,
      wrinklesV2: result.wrinklesV2,
      texture: result.texture,
      darkCircles: result.darkCircles,
      darkCirclesV2: result.darkCirclesV2,
      moisture: result.moisture,
      oiliness: result.oiliness,
      redness: result.redness,
      eyeBags: result.eyeBags,
      acne: result.acne,
      droopyUpperEyelid: result.droopyUpperEyelid,
      droopyLowerEyelid: result.droopyLowerEyelid,
      firmness: result.firmness,
      radiance: result.radiance,
      pore: result.pore,
    };
  },
  getSkinTypeResultI18n: v => _get(utils.getSkinTypeConfig(v), 'label'),
  getSkinTypeZoneConfig: v => {
    switch (v) {
      case skintypeZones.tzone:
        return {
          key: skintypeZones.tzone,
          label: ['business:p.b.s.s.d.skincare.skintype.zone.tzone', 'T-zone'],
          titleLabel: [
            'business:p.b.s.s.d.result.skintype.title.tzone',
            'Your t-zone skin type',
          ],
          img:
            'https://plugins-media.makeupar.com/wcm-preview/demostore/231213-skincare-skintype-zones/item0-231213030245/img_webcm_skincare_widget_type_tzone.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.zone.tzone',
            'The “T-zone” refers to the central area of your face that includes the forehead, nose, and chin. This region is often more prone to oiliness and can be a common area for breakouts.',
          ],
        };
      case skintypeZones.uzone:
        return {
          key: skintypeZones.uzone,
          label: ['business:p.b.s.s.d.skincare.skintype.zone.uzone', 'U-zone'],
          titleLabel: [
            'business:p.b.s.s.d.result.skintype.title.uzone',
            'Your u-zone skin type',
          ],
          img:
            'https://plugins-media.makeupar.com/wcm-preview/demostore/231213-skincare-skintype-zones/item0-231213030245/img_webcm_skincare_widget_type_uzone.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.zone.uzone',
            'The “U-zone” refers to the area on your face that includes the cheeks and jawline. This region can have different needs compared to the T-zone, as it may be drier and more sensitive. ',
          ],
        };
      case skintypeZones.full:
        return {
          key: skintypeZones.full,
          label: [
            'business:p.b.s.s.d.skincare.skintype.zone.full',
            'FULL FACE',
          ],
          titleLabel: [
            'business:p.b.s.s.d.result.skintype.title',
            'Your skin type',
          ],
          img:
            'https://plugins-media.makeupar.com/wcm-preview/demostore/231213-skincare-skintype-zones/item0-231213030245/img_webcm_skincare_widget_type_full.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.zone.full',
            'Your “full face” involves a comprehensive skin areas on your face, including the T-zone, U-zone, and everything in between.',
          ],
        };
    }
  },
  getSkinTypeAspectConfig: v => {
    switch (v) {
      case skintypeAspects.dryness:
        return {
          key: skintypeAspects.dryness,
          label: [
            'business:p.b.s.s.d.skincare.skintype.result.type.dryness',
            'Dryness',
          ],
          color: '#025C88',
        };
      case skintypeAspects.oiliness:
        return {
          key: skintypeAspects.oiliness,
          label: [
            'business:p.b.s.s.d.skincare.skintype.result.type.oiliness',
            'Oiliness',
          ],
          color: '#FF8B0C',
        };
      case skintypeAspects.redness:
        return {
          key: skintypeAspects.redness,
          label: [
            'business:p.b.s.s.d.skincare.skintype.result.type.redness',
            'Redness',
          ],
          color: '#FF2E00',
        };
    }
  },
  getSkinTypeConfig: (v, {fullFaceResult, tZoneResult, uZoneResult} = {}) => {
    switch (v) {
      case skintypes.combination:
        return {
          key: skintypes.combination,
          label: [
            'business:p.b.s.s.d.result.skintype.combination',
            'Combination',
          ],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_combi.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.combination',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]:
              uZoneResult === skintypes.normal ? 'low' : 'high',
            [skintypeAspects.oiliness]: 'high',
            [skintypeAspects.redness]: 'low',
          },
        };
      case skintypes.combinationSensitive:
        return {
          key: skintypes.combinationSensitive,
          label: [
            'business:p.b.s.s.d.result.skintype.combinationsensitive',
            'Combination & Sensitive',
          ],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_combi_sen.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.combinationsensitive',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]: 'high',
            [skintypeAspects.oiliness]: 'high',
            [skintypeAspects.redness]: 'high',
          },
        };
      case skintypes.dry:
        return {
          key: skintypes.dry,
          label: ['business:p.b.s.s.d.result.skintype.dry', 'Dry'],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_dry.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.dry',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]: 'high',
            [skintypeAspects.oiliness]: 'low',
            [skintypeAspects.redness]: 'low',
          },
        };
      case skintypes.drySensitive:
        return {
          key: skintypes.drySensitive,
          label: [
            'business:p.b.s.s.d.result.skintype.drysensitive',
            'Dry & Sensitive',
          ],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_dry_sen.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.drysensitive',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]: 'high',
            [skintypeAspects.oiliness]: 'low',
            [skintypeAspects.redness]: 'high',
          },
        };
      case skintypes.normal:
        return {
          key: skintypes.normal,
          label: ['business:p.b.s.s.d.result.skintype.normal', 'Normal'],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_normal.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.normal',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]: 'low',
            [skintypeAspects.oiliness]: 'low',
            [skintypeAspects.redness]: 'low',
          },
        };
      case skintypes.normalSensitive:
        return {
          key: skintypes.normalSensitive,
          label: [
            'business:p.b.s.s.d.result.skintype.normalsensitive',
            'Sensitive',
          ],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_sensitive.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.normalsensitive',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]: 'low',
            [skintypeAspects.oiliness]: 'low',
            [skintypeAspects.redness]: 'high',
          },
        };
      case skintypes.oily:
        return {
          key: skintypes.oily,
          label: ['business:p.b.s.s.d.result.skintype.oily', 'Oily'],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_oily.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.oily',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]: 'low',
            [skintypeAspects.oiliness]: 'high',
            [skintypeAspects.redness]: 'low',
          },
        };
      case skintypes.oilySensitive:
        return {
          key: skintypes.oilySensitive,
          label: [
            'business:p.b.s.s.d.result.skintype.oilysensitive',
            'Oily & Sensitive',
          ],
          img:
            'https://plugins-media.makeupar.com/webconsultation/images/skincare-widget/img_webcm_skincare_widget_type_oily_sen.png',
          desc: [
            'business:p.b.s.s.d.skincare.skintype.info.oilysensitive',
            'Combination skin type is a skin condition characterized by having both oily and dry areas on the face. Typically, the T-zone (forehead, nose, and chin) is oily while the cheeks and other areas may be dry or normal. ',
          ],
          aspectResults: {
            [skintypeAspects.dryness]: 'low',
            [skintypeAspects.oiliness]: 'high',
            [skintypeAspects.redness]: 'high',
          },
        };
      case skintypes.unknown:
        return {
          key: skintypes.unknown,
          label: ['business:p.b.s.s.d.result.skintype.unknown', 'Unknown'],
          aspectResults: {
            [skintypeAspects.dryness]: 'unknown',
            [skintypeAspects.oiliness]: 'unknown',
            [skintypeAspects.redness]: 'unknown',
          },
        };
      default:
        return {label: []};
    }
  },
  toSkinAnalysisFeature: feature => {
    switch (feature) {
      case 'eyebag':
      case 'eyeBags':
        return 'eyeBags';
      case 'darkCircles':
        return 'darkCirclesV2';
      default:
        return feature;
    }
  },
  toUIFeature: feature => {
    switch (feature) {
      case 'ageSpots':
        return 'spots';
      default:
        return feature;
    }
  },
  splitArrayIntoChunks: (arr = [], chunkSize = 0) => {
    if (!chunkSize || !arr.length) {
      return arr;
    }
    const chunkLength = Math.ceil(arr.length / chunkSize);
    return Array.from({length: chunkLength}, (chunk, i) =>
      arr.slice(i * chunkSize, i * chunkSize + chunkSize)
    );
  },
  isBackgroundSupported: type => type === 'look',
  getStrapiKey: (productName = utils.getProductName()) => {
    switch (productName) {
      case 'eye-wear':
        return 'DEMO_STORE_EYE_WEAR';
      case 'nail-color':
        return 'DEMO_STORE_NAIL_COLOR';
      case 'hair-color':
        return 'DEMO_STORE_HAIR_COLOR';
      case 'virtual-hairstyles':
        return 'DEMO_STORE_HAIR_STYLE';
      case 'watches':
        return 'DEMO_STORE_WATCH';
      case demostoreStrapiPage.watch2d:
        return 'DEMO_STORE_2D_WATCH';
      case 'face-analyzer':
        return 'DEMO_STORE_FACE_ANALYZER';
      case 'lip-color':
        return 'DEMO_STORE_LIP_COLOR';
      case 'concealer':
        return 'DEMO_STORE_CONCEALER';
      case 'rings':
        return 'DEMO_STORE_RING';
      case demostoreStrapiPage.ring2d:
        return 'DEMO_STORE_2D_RING';
      case 'earrings':
        return 'DEMO_STORE_EARRING';
      case demostoreStrapiPage.earrings2d:
        return 'DEMO_STORE_2D_EARRING';
      case 'bracelets':
        return 'DEMO_STORE_BRACELET';
      case demostoreStrapiPage.bracelet2d:
        return 'DEMO_STORE_2D_BRACELET';
      case 'necklace':
        return 'DEMO_STORE_NECKLACE';
      case demostoreStrapiPage.necklace2d:
        return 'DEMO_STORE_2D_NECKLACE';
      case 'hat':
        return 'DEMO_STORE_HAT';
      case 'headband':
        return 'DEMO_STORE_HEADBAND';
      case demostoreStrapiPage.skincare:
        return 'DEMO_STORE_SKIN_ANALYSIS';
      case demostoreStrapiPage.emulation:
        return 'DEMO_STORE_SKIN_EMULATION';
      case 'beard-style':
        return 'DEMO_STORE_BEARD_STYLE';
      case 'beard-dye':
        return 'DEMO_STORE_BEARD_DYE';
      case 'eye-color':
        return 'DEMO_STORE_EYE_COLOR';
      case 'shadefinder':
        return 'DEMO_STORE_SHADE_FINDER';
      case '3d-viewer':
        return 'DEMO_STORE_3D_VIEWER';
      case 'eyebrow':
        return 'DEMO_STORE_EYEBROW';
      case 'contour':
        return 'DEMO_STORE_CONTOUR';
      case 'eyelashes':
        return 'DEMO_STORE_EYELASHES';
      case 'face-reshaping':
        return 'DEMO_STORE_FACE_RESHAPING';
      case 'set':
        return 'DEMO_STORE_JEWELRY_WATCH_SET';
      case 'hair-type':
        return 'DEMO_STORE_HAIR_TYPE';
      case 'hair-length':
        return 'DEMO_STORE_HAIR_LENGTH';
      case 'hair-frizziness':
        return 'DEMO_STORE_HAIR_FRIZZINESS';
      case 'look':
        return 'DEMO_STORE_LOOK';
      case 'youcam-tutorial':
        return 'DEMO_STORE_YOUCAM_TUTORIAL';
      case 'lip-liner':
        return 'DEMO_STORE_LIP_LINER';
      case 'lip-plumper':
        return 'DEMO_STORE_LIP_PLUMPER';
      case 'eye-liner':
        return 'DEMO_STORE_EYE_LINER';
      case 'eye-shadow':
        return 'DEMO_STORE_EYE_SHADOW';
      case 'blush':
        return 'DEMO_STORE_BLUSH';
      case 'foundation':
        return 'DEMO_STORE_FOUNDATION';
      case 'bronzer':
        return 'DEMO_STORE_BRONZER';
      case 'highlighter':
        return 'DEMO_STORE_HIGHLIGHTER';
      case 'personality':
        return 'DEMO_STORE_PERSONALITY';
      default:
        return '';
    }
  },
  getModuleBackground: () => ({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage:
      'url(https://plugins-media.makeupar.com/webconsultation/images/bg1.jpg)',
  }),
  getBeardModuleBackground: () => ({
    ...utils.getModuleBackground(),
    backgroundImage: `url(${require('../assets/images/business/showcase/modulebackground/beard-background.jpg')})`,
  }),
  getHandARModuleBackground: () => ({
    ...utils.getModuleBackground(),
    backgroundPosition: 'top',
  }),
  getNailModuleBackground: () => ({
    ...utils.getModuleBackground(),
    backgroundPosition: 'top',
    backgroundImage: `url(${require('../assets/images/business/showcase/modulebackground/nail-background.jpg')})`,
  }),
  sleep: (ms = 0) => new Promise(resolve => setTimeout(() => resolve(), ms)),
  getYmkIframeDocument: () => {
    const iframe = document.querySelector('#YMK-module-iframe');
    return iframe && (iframe.contentDocument || iframe.contentWindow.document);
  },
  prefetchSkincareProducts: async (apiKey = utils.getApiKey()) => {
    try {
      const r = await fetch(
        `${wcmApiPrefix}/query-skincare-setting.action?apiKey=${apiKey}`,
        {method: 'GET'}
      );
      if (r.status !== 200) {
        return;
      }
      const productIds = JSON.parse(_get(await r.json(), 'productIds', '[]'));
      const urls = _map(
        productIds,
        id =>
          id &&
          `${wcmApiPrefix}/query-skincare.action?skincareId=${id}&apiKey=${apiKey}`
      );
      utils.prefetch(urls);
    } catch (e) {
      console.error('Failed to prefetch skincare products', e);
    }
  },
  getModuleIframeDoc: () => {
    const iframe = document.querySelector('#YMK-module iframe');
    return (
      _get(iframe, 'contentWindow.document') || _get(iframe, 'contentDocument')
    );
  },
};

export default utils;
