import featurecutoutimage from './feature-cutout-image/routes';
import featuredoublechinremover from './feature-double-chin-remover/routes';
import featurevideostyletransfer from './feature-video-style-transfer/routes';
import featurevideohaircolorchanger from './feature-video-hair-color-changer/routes';
import featurevideofaceblur from './feature-video-face-blur/routes';
import featurevideobackgroundremoval from './feature-video-background-removal/routes';
import featuretexttoimageai from './feature-text-to-image-ai/routes';
import featureskintonechanger from './feature-skin-tone-changer/routes';
import featureskinsmoother from './feature-skin-smoother/routes';
import featurereshapefaceinvideo from './feature-reshape-face-in-video/routes';
import featurephotoeffect from './feature-photo-effect/routes';
import featuremakelegslonger from './feature-make-legs-longer/routes';
import featureeyebagremover from './feature-eye-bag-remover/routes';
import featureblurimage from './feature-blur-image/routes';
import featureaddtexttophoto from './feature-add-text-to-photo/routes';
import featureaifaceswap from './feature-ai-face-swap/routes';
import featureaiphotoenhancer from './feature-ai-photo-enhancer/routes';
import featureairemoval from './feature-ai-removal/routes';
import featureaivideoenhance from './feature-ai-video-enhance/routes';
import featurebackgroundremoval from './feature-background-removal/routes';
import featurebodyeditor from './feature-body-editor/routes';
import featurehaircolorchanger from './feature-hair-color-changer/routes';
import featurevideoskinretouch from './feature-video-skin-retouch/routes';
import featureimagetovideo from './feature-image-to-video/routes';
import featureteethwhitener from './feature-teeth-whitener/routes';
import featurevideofaceswap from './feature-video-face-swap/routes';
import featurevideobodyeditor from './feature-video-body-editor/routes';
import featurephotocollage from './feature-photo-collage/routes';
import featuremakeupeditor from './feature-makeup-editor/routes';
import featurefaceeditor from './feature-face-editor/routes';

export default [
  featureaifaceswap,
  featureaiphotoenhancer,
  featureairemoval,
  featureaivideoenhance,
  featurebackgroundremoval,
  featurebodyeditor,
  featurehaircolorchanger,
  featurevideoskinretouch,
  featureimagetovideo,
  featureteethwhitener,
  featurevideofaceswap,
  featurevideobodyeditor,
  featurephotocollage,
  featuremakeupeditor,
  featurefaceeditor,
  featureaddtexttophoto,
  featureblurimage,
  featureeyebagremover,
  featuremakelegslonger,
  featurephotoeffect,
  featurereshapefaceinvideo,
  featureskinsmoother,
  featureskintonechanger,
  featuretexttoimageai,
  featurevideobackgroundremoval,
  featurevideofaceblur,
  featurevideohaircolorchanger,
  featurevideostyletransfer,
  featuredoublechinremover,
  featurecutoutimage,
];
