import React from 'react';
import {Route} from 'react-router';
import {useTranslation} from 'react-i18next';
import useScrollToTop from '../hooks/useScrollToTop';
import LazyLoad from 'react-lazyload';
import '../../assets/styles/languages/languagefontfamily.css';
import classnames from 'classnames';
import Footer4ConsumerAsync from './footer4consumer/async';
import FadeTransition from '../transition/fade';
import webUtils from '../../utils/webUtils';

export default ({
  component: Component,
  backgroundImage,
  breadcrumb = [],
  ...rest
}) => {
  useScrollToTop();
  const {i18n} = useTranslation();

  return (
    <Route
      {...rest}
      render={matchProps => (
        <FadeTransition>
          <div
            className={classnames({
              'pf-language': true,
              'pf-language-ja': i18n.language === 'ja',
            })}
            style={{
              background:
                backgroundImage &&
                `url(${backgroundImage}) no-repeat 50% fixed`,
              backgroundSize: 'cover',
            }}
          >
            <div
              style={{
                minHeight: '100vh',
                overflowX: 'hidden',
                overflowY: 'hidden',
              }}
            >
              <Component {...matchProps} breadcrumb={breadcrumb} />
            </div>
            {!webUtils.isMobile() ? (
              <Footer4ConsumerAsync pageReady={true} />
            ) : (
              <LazyLoad
                height={500}
                once={true}
                unmountIfInvisible={true}
                throttle={100}
              >
                <Footer4ConsumerAsync pageReady={true} />
              </LazyLoad>
            )}
          </div>
        </FadeTransition>
      )}
    />
  );
};
