import React from "react";
import { Route } from "react-router-dom";
import AsyncPage from "./async";

export default [
  <Route
    key="/business/products/frizzy-hair-analyzer"
    exact
    path="/business/products/frizzy-hair-analyzer"
    component={AsyncPage}
  />,
];