import classnames from 'classnames';
import {Trans} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import _isEmpty from 'lodash/isEmpty';
import Link from '../../../../link';
import ArrowIcon from '../ArrowIcon';
import pathUtils from '../../../../../utils/pathUtils';

export default function ExpandableList({
  title,
  menus,
  list = [],
  closeMenu = () => {},
  setIndex = () => {},
  index,
  id,
}) {
  const [isExpand, setIsExpand] = useState(false);
  const isActive = path =>
    window.location.pathname === pathUtils.addLangPrefix(path);
  useEffect(() => {
    if (index !== id) setIsExpand(false);
  }, [index]);

  return (
    <div>
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--opened': isExpand,
        })}
        onClick={() => {
          setIndex(id);
          setIsExpand(!isExpand);
        }}
      >
        {title}
        <ArrowIcon isShow={isExpand} color="grey" />
      </div>

      {menus && (
        <div style={{display: isExpand ? 'block' : 'none'}}>{menus}</div>
      )}
      {!menus &&
        !_isEmpty(list) &&
        list.map((m, i) => (
          <div
            key={'menu4-' + i}
            className={classnames({
              'pf-header-mobile-business-menus__subitem-category': true,
              'pf-header-mobile-business-menus__subitem-category-item--active': isActive(
                m.link
              ),
              'pf-header-mobile-business-menus--collapse': !isExpand,
            })}
          >
            <Link to={m.link} onClick={closeMenu}>
              <Trans i18nKey={m.i18nKey} />
            </Link>
          </div>
        ))}
    </div>
  );
}
