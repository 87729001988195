import forgotpassword from './forgotpassword/routes';
import freetrial from './freetrial/routes';
import resetpassword from './resetpassword/routes';
import setuppassword from './setuppassword/routes';
import signin from './signin/routes';
import verifyemail from './verifyemail/routes';
import appsignin from './appsignin/routes';
import userhome from './home/routes';

export default [
  forgotpassword,
  freetrial,
  resetpassword,
  setuppassword,
  signin,
  verifyemail,
  appsignin,
  userhome,
];
