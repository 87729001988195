import React, {useState} from 'react';
import headerUtils from '../../../utils/headerUtils';
import PFLink from '../../link';
import './appfeatures.css';
import {Trans} from 'react-i18next';
import classnames from 'classnames';
import webUtils from '../../../utils/webUtils';
import ArrowIcon from '../header/component/ArrowIcon';

export default function AppFeatures() {
  const [index, setIndex] = useState('pe');
  const getLabel = (text, id) => {
    if (!webUtils.isMobile()) return text;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '90%',
          margin: '0 auto',
        }}
        onClick={() => {
          if (id === index) return setIndex('');
          setIndex(id);
        }}
      >
        <div>{text}</div>
        <ArrowIcon isShow={index === id} color="grey" />
      </div>
    );
  };

  const getItem = (title, id, list) => {
    return (
      <div>
        <div
          className={classnames({
            'app-feature-menu--label': true,
            'app-feature-menu--label--active':
              webUtils.isMobile() && index === id,
          })}
        >
          {getLabel(title, id)}
        </div>
        {(index === id || !webUtils.isMobile()) && (
          <div
            className={classnames({
              'app-feature-menu--link-container': true,
              'app-feature-menu--label--active':
                webUtils.isMobile() && index === id,
            })}
          >
            {list.map(s => (
              <PFLink
                to={s.link}
                key={s.id}
                className={classnames({
                  'app-feature-menu--title-active':
                    window.location.pathname.indexOf(s.link) !== -1,
                })}
              >
                {s.text}
              </PFLink>
            ))}
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      className={classnames({
        'pf-subheader-menu': !webUtils.isMobile(),
        'app-feature--menu': webUtils.isMobile(),
      })}
      style={{width: '100vw'}}
    >
      <div className="app-feature-menu--wrapper">
        {getItem(
          <Trans i18nKey="business:u.c.leftmenu.photo.editing">
            Photo Editing
          </Trans>,
          'pe',
          headerUtils.photoEditing
        )}
        {getItem(
          <Trans i18nKey="business:u.c.leftmenu.portrait.editing">
            Portrait Editing
          </Trans>,
          'ped',
          headerUtils.portraitEditing
        )}
        {getItem(
          <Trans i18nKey="business:u.c.leftmenu.video.editing">
            Video Editing
          </Trans>,
          've',
          headerUtils.videoEditing
        )}
      </div>
    </div>
  );
}
