import React from 'react';
import AsyncPage from './async';
import BlankLayout from '../../../../components/layout/BlankLayout';

export default [
  <BlankLayout
    key="/perfectbeauty/youcam/terms-of-service-yat"
    exact
    path="/perfectbeauty/youcam/terms-of-service-yat"
    component={AsyncPage}
  />,
];
