import React from 'react';
import PinkButton from '../../../../components/button/pinkbutton';
import {Trans} from 'react-i18next';
import _flatten from 'lodash/flatten';
import smbLinks from '../../../../utils/config/smbLinks';
import webUtils from '../../../../utils/webUtils';
import './signincard.css';
import FormattedDate from '../../../../components/FormattedDate';
import authDao from '../../../../dao/authDao';
import PFLink from '../../../../components/link';
import FreeTrialButton from '../../../../components/button/freetrialbutton';
import _get from 'lodash/get';

export default function SignInCard({data}) {
  const config = _flatten(Object.values(smbLinks)).filter(
    s => s.solutionType === data.solutionType
  )[0];
  const getInfo = () => {
    if (data.isOverdue || !_get(data, 'isTrial'))
      return (
        <div className={'function-card-content'}>
          {data.isOverdue && (
            <Trans i18nKey="business:p.b.h.solution.expired">Expired</Trans>
          )}
          <PFLink
            to={webUtils.getOnlineServiceLink(data.solutionType).feature}
            className={'function-card-content-link'}
          >
            <Trans i18nKey="business:p.b.h.solution.learn.more">
              Learn more
            </Trans>
          </PFLink>
        </div>
      );
    if (!data.isOwner) {
      return (
        <div className={'function-card-content'}>
          <div>
            <span>
              <Trans i18nKey="business:p.b.u.team.member">Team member</Trans>
            </span>
          </div>
          <div>{data.customerName}</div>
        </div>
      );
    }
    return (
      <div className={'function-card-content'}>
        {data.trialPlan ? (
          <div>
            <Trans i18nKey="business:p.b.u.free.trial">Free Trial</Trans>{' '}
          </div>
        ) : (
          <div>
            <span>{data.subscriptionType.split('_')[0]}</span> Plan
            {data.subscriptionType !== 'ENTERPRISE' && (
              <span> - {data.recurringType}</span>
            )}
          </div>
        )}
        Ends on {<FormattedDate time={data.endTime} />}
      </div>
    );
  };
  const getCta = () => {
    if ((!data.isOverdue || data.isOwner) && _get(data, 'isTrial')) {
      return (
        <PinkButton
          className="cta"
          square={true}
          text={
            <Trans i18nKey="business:p.b.u.enter.the.console">
              Enter the Console
            </Trans>
          }
          func={() => {
            authDao.switchSolutionType(data.customerId).then(res => {
              if (res.status === 'success') {
                window.location.href = webUtils.getSMBEnvReactAppUrl({
                  solutionType: data.solutionType,
                });
              }
            });
          }}
        />
      );
    }
    return (
      <FreeTrialButton
        text={
          <div className="signin-card--cta-arrow">
            <Trans i18nKey="business:c.l.h.mobile.business.start.trial">
              Start free trial
            </Trans>
            <img
              alt=""
              src={require('../../../../assets/images/icon/arrow.svg')}
            />
          </div>
        }
        bgColor={'white'}
        className="cta"
        serviceType={{
          functionType: webUtils.getOnlineServiceLink(data.solutionType)
            .functionType,
          solutionType: data.solutionType,
        }}
      />
    );
  };

  if (!data) return <div />;

  return (
    <div key={`functionType-btn${data.id}`} className="function-card">
      <div className="icon">
        <img alt="" src={config.demoIcon} />
        <div className="function-card-label">
          {config.demoTitle}
          {webUtils.isMobile() && getInfo()}
        </div>
      </div>
      {!webUtils.isMobile() && getInfo()}
      <div className="cta-wrapper">{getCta()}</div>
    </div>
  );
}
