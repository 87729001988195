import React, {useContext, useState} from 'react';
import classnames from 'classnames';
import Logo from './Logo';
import Menus from './Menus';
import Language from './Language';
import Container from '../container';
import MobileMenus from './MobileMenus';
import './index.css';
import Link from '../../link';
import {Trans, useTranslation} from 'react-i18next';
import webUtils from '../../../utils/webUtils';
import GoogleSearch from '../googlesearch';
import useWindowWidth from '../../hooks/useWindowWidth';
import PopupMenu2 from '../../content/popupmenu2/async';
import MobileAvatarMenus from './MobileAvatarMenus';
import Avatar from '../../account/Avatar';
import {AuthContext} from '../../auth';

export default function Header({isBusiness, isNewHeader, isScrolled}) {
  const [popupMenu, setPopupMenu] = useState(false);
  const {i18n} = useTranslation();
  const width = useWindowWidth();
  const {isFirstLoaded, accountSummary} = useContext(AuthContext);
  const isSignIn = isFirstLoaded && accountSummary;
  const [isAvatarMenuOpen, setIsAvatarMenuOpen] = useState(false);
  const account = accountSummary ? accountSummary.account : undefined;

  const perfectLogo = (
    <Logo isBusiness={isBusiness} setPopupMenu={setPopupMenu} />
  );
  const businessBtn = (
    <Menus
      isBusiness={isBusiness}
      gtmClassNamePrefix={`gtm_${isBusiness ? 'business' : 'consumer'}_header`}
      isNewHeader={isNewHeader}
    />
  );
  const googleSearch = width > 600 && (
    <GoogleSearch
      isScrolled={isScrolled}
      is2b={webUtils.isBusiness()}
      isNewHeader={isNewHeader}
    />
  );
  const ir = (
    <Link
      className="pf-header__investors"
      to="https://ir.perfectcorp.com/"
      style={{color: isNewHeader && isScrolled && '#212529'}}
    >
      <Trans i18nKey="business:c.l.s.rightmenu.investors">Investors</Trans>
    </Link>
  );

  const signInBtn =
    webUtils.isBusiness() &&
    (isSignIn ? (
      <PopupMenu2
        component={<MobileAvatarMenus />}
        theme="white"
        show={isAvatarMenuOpen}
        setPopupMenu={setIsAvatarMenuOpen}
      >
        <div className="pf-header__user-icon pf-header__mobile-menus-icon">
          <Avatar
            imageUrl={account.imageUrl}
            width={27}
            height={27}
            style={{cursor: 'pointer'}}
          />
          {width > 800 && (
            <span
              className="flex-grow-1"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginLeft: '5px',
                color: isNewHeader ? (isScrolled ? '#343a40' : '#fff') : '#fff',
              }}
            >{`${account.firstName} ${account.lastName}`}</span>
          )}
        </div>
      </PopupMenu2>
    ) : (
      <>
        {width > 600 && (
          <Link
            className="pf-header__sign-in"
            to="/business/user/sign-in"
            style={{color: isNewHeader && isScrolled && '#212529'}}
          >
            <Trans i18nKey="business:c.l.s.rightmenu.sign.in">Sign In</Trans>
          </Link>
        )}
      </>
    ));

  const languageBtn = (
    <Language isBusiness={isBusiness} isScrolled={isScrolled} />
  );
  const mobileMenu = (
    <MobileMenus
      isBusiness={isBusiness}
      isScrolled={isScrolled}
      popupMenu={popupMenu}
      setPopupMenu={setPopupMenu}
      isNewHeader={isNewHeader}
    />
  );
  return (
    <div className={classnames({'pf-header': true, v1: !isNewHeader})}>
      <Container style={{maxWidth: 'none'}} className="pf-header-container">
        <div className="pf-header__left">
          {perfectLogo}
          {businessBtn}
        </div>
        <div
          className="pf-header__right"
          style={{
            flexGrow: isBusiness
              ? width <= 600 && (isSignIn ? '0.2' : '0')
              : 0.03,
          }}
        >
          {googleSearch}
          {ir}
          {signInBtn}
          {languageBtn}
          {webUtils.isMobile() && mobileMenu}
        </div>
      </Container>
    </div>
  );
}
