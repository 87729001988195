import React, {useState} from 'react';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import './MobileConsumerMenus.css';
import './MobileBusinessMenus.css';
import {Trans, useTranslation} from 'react-i18next';
import GoogleSearch from '../googlesearch';
import headerUtils from '../../../utils/headerUtils';
import ExpandableList from './component/expandablelist';
import AppFeatures from '../subheader4consumer/AppFeatures';
import productLinks from '../../../utils/config/productLinks';
import PFLink from '../../link';

export default function MobileConsumerMenus({setPopupMenu, setIndex, index}) {
  const {i18n} = useTranslation();
  const {location} = useReactRouter();
  const closeMenu = () => {
    setIndex('');
    setPopupMenu(false);
  };

  return (
    <div className="pf-header-mobile-consumer-menus">
      <div className="pf-header-mobile-consumer-menus__item">
        <GoogleSearch is2b={false} />
      </div>
      <ExpandableList
        title={<Trans i18nKey="consumer:u.c.applinks.home">Our Apps</Trans>}
        list={headerUtils.consumerApps}
        setIndex={setIndex}
        index={index}
        id={'ourapps'}
        closeMenu={closeMenu}
      />
      <ExpandableList
        title={
          <Trans i18nKey="consumer:u.c.leftmenu.app.feature">
            App Features
          </Trans>
        }
        menus={<AppFeatures />}
        setIndex={setIndex}
        index={index}
        id={'appfeature'}
        closeMenu={closeMenu}
      />
      <PFLink isYce={true} className="pf-header-mobile-consumer-menus__item">
        <div
          className={classnames({
            'pf-subheader-4-consumer__left-menu--active':
              location.pathname.indexOf('/consumer/blog') !== -1,
            'pf-content-popupmenu1 pf-content-popupmenu1--white': true,
          })}
          style={{color: '#ffffff'}}
        >
          {i18n.t('consumer:u.c.online.editor', 'Online Editor')}
        </div>
      </PFLink>
      <PFLink
        className="pf-header-mobile-consumer-menus__item"
        to={'/consumer/blog'}
      >
        <div
          className={classnames({
            'pf-subheader-4-consumer__left-menu--active':
              location.pathname.indexOf('/consumer/blog') !== -1,
            'pf-content-popupmenu1 pf-content-popupmenu1--white': true,
          })}
          style={
            location.pathname.indexOf('/consumer/blog') !== -1
              ? {}
              : {color: '#ffffff'}
          }
        >
          <Trans i18nKey="consumer:u.c.blog">Blog</Trans>
        </div>
      </PFLink>
      <PFLink
        isYce={true}
        yceKeyword={'ai-api'}
        className="pf-header-mobile-consumer-menus__item"
      >
        <div
          className={classnames({
            'pf-content-popupmenu1 pf-content-popupmenu1--white': true,
          })}
          style={{color: '#ffffff'}}
        >
          {productLinks.aiapi.text}
        </div>
      </PFLink>
      <div
        className={classnames({
          'pf-header-mobile-consumer-menus__item': true,
        })}
        onClick={() => {
          window.location.href = 'https://ir.perfectcorp.com/';
        }}
      >
        <div
          className={classnames({
            'pf-content-popupmenu1 pf-content-popupmenu1--white': true,
          })}
          style={{color: '#ffffff'}}
        >
          <Trans i18nKey="business:c.l.s.rightmenu.investors">Investors</Trans>
        </div>
      </div>
    </div>
  );
}
