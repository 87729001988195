import React, {useContext, useState} from 'react';
import webUtils from '../../../utils/webUtils';
import PinkButton from '../pinkbutton';
import {Trans, useTranslation} from 'react-i18next';
import userUtils from '../../../utils/userUtils';
import {AuthContext} from '../../auth';
import autoCompleteSignUpUtils from '../../../utils/autoCompleteSignUpUtils';
import _get from 'lodash/get';

export default function FreeTrialButton({
  text,
  gtm,
  style = {},
  bgColor,
  className,
  serviceType = {},
}) {
  const {i18n, t} = useTranslation();
  const {accountSummary} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const functionType =
    _get(serviceType, 'functionType') ||
    webUtils.getOnlineServiceLink().functionType;
  const solutionType =
    _get(serviceType, 'solutionType') ||
    webUtils.getOnlineServiceLink().solutionType;
  const affiliationDays = userUtils.getAffiliationDays({
    solutionType,
  });
  const {completeFreeTrial} = autoCompleteSignUpUtils({
    i18n,
    functionType,
    solutionType,
  });
  return (
    <PinkButton
      className={className}
      gtm={gtm || ''}
      text={
        <div className="text-center">
          {isLoading && <div className="spinner-border spinner-border-sm" />}
          <div
            style={{
              visibility: isLoading ? 'hidden' : 'visible',
            }}
          >
            {text || (
              <Trans i18nKey="business:p.b.p.pricing.smb.compare.table2.try.free30">
                {{affiliationDays}} Days Free Trial
              </Trans>
            )}
          </div>
        </div>
      }
      style={{...style}}
      square={true}
      bgColor={bgColor || 'blue-purple-linear'}
      func={() => {
        setIsLoading(true);
        completeFreeTrial({accountSummary}).then(res => {
          if (!res) return;
          setTimeout(() => {
            window.location.href = webUtils.getSMBEnvReactAppUrl({
              solutionType,
            });
          }, 200);
        });
      }}
    />
  );
}
