import React, {useEffect, useState, useMemo} from 'react';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import _throttle from 'lodash/throttle';
import classnames from 'classnames';
import Auth from '../../auth';
import Header from '../../layout/header';
import webUtils from '../../../utils/webUtils';
import storeUtils from '../../../utils/storeUtils';
import SubHeader from '../../layout/subheader/async';
import SubHeader4Consumer from '../../layout/subheader4consumer/async';
import CookieStatement from '../../layout/header/CookieStatement';
import useWindowWidth from '../../hooks/useWindowWidth';
import _indexOf from 'lodash/indexOf';
import './index.css';
import DefaultHeader from '../../layout/defalutheader';
import PreviewBanner from '../../layout/previewBanner';
import ProductHeader from './component/productheader';
import GetStrapiContent from '../../strapi/getstrapicontent';
import _isEmpty from 'lodash/isEmpty';

export default function FadeTransition({
  children,
  buttons,
  setProductType,
  productType,
  show,
  setIsSliding,
  headerStyle = {},
}) {
  const location = useLocation();
  const {i18n} = useTranslation();
  const langParam = webUtils.getLangPath(i18n.language);
  const isBusiness = webUtils.isBusiness();
  const [isLoaded, setIsLoaded] = useState(false);
  const isSmb = webUtils.isSmb();

  const {strapiContent} = GetStrapiContent(
    'YOU_CAM_VIDEO',
    setIsLoaded,
    location,
    false
  );
  const isNewHeader = useMemo(() => {
    if (
      window.location.pathname.indexOf('ycv') !== -1 &&
      isLoaded &&
      _isEmpty(strapiContent)
    )
      return false;
    return webUtils.isNewHeader();
  }, [window.location.pathname, isLoaded, strapiContent]);
  // remove fade in due to try on different categories
  function getLocationPathname(l) {
    if (l.pathname.indexOf('/business/showcase') !== -1) {
      return '/business/showcase/lip-color';
    }
    return l.pathname;
  }
  const [hideStatement, setHideStatement] = useState(true);
  useEffect(() => {
    if (window.navigator.userAgent === 'ReactSnap') {
      return;
    }
    setHideStatement(storeUtils.getCookieStatement());
  }, []);

  const getHeader = (location, isScrolled) => {
    const isSdkPrivacy =
      _indexOf(
        [`${langParam}/business/SDK/privacy`],
        window.location.pathname
      ) !== -1;
    if (window.location.href.indexOf('/highlight/ymk') !== -1) return <div />;
    if (isSdkPrivacy) {
      return <DefaultHeader title={'SDK Privacy Policy'} />;
    }
    return (
      <>
        <Header
          isBusiness={isBusiness}
          width={width}
          isNewHeader={isNewHeader}
          isScrolled={isScrolled}
        />
        {width > 600 ? (
          isBusiness ? (
            <SubHeader isSmb={isSmb} width={width} isNewHeader={isNewHeader} />
          ) : (
            <SubHeader4Consumer width={width} isNewHeader={isNewHeader} />
          )
        ) : null}
        <ProductHeader
          buttons={buttons}
          setProductType={setProductType}
          productType={productType}
          show={show}
          setIsSliding={setIsSliding}
        />
      </>
    );
  };
  const width = useWindowWidth();
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);

  const handleScroll = _throttle(() => {
    setScrollPosition(window.pageYOffset);
  }, 200);

  useEffect(() => {
    setScrollPosition(0);
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [isNewHeader]);

  return (
    <Auth>
      <PreviewBanner />
      <div
        className={classnames({
          'pf-language': true,
          'pf-language-ja': i18n.language === 'ja',
          'fade-header': true,
          v1: !isNewHeader,
          scrolled: scrollPosition > 0,
          // hide: isApps() && width > 800,
        })}
        style={{position: 'sticky', ...headerStyle}}
      >
        {getHeader(location, scrollPosition > 0)}
      </div>
      {children}
      <CookieStatement
        hideStatement={!!hideStatement}
        setHideStatement={setHideStatement}
      />
    </Auth>
  );
}
