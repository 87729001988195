import React from 'react';
import Link from '../../link';
import {HashLink} from 'react-router-hash-link';
import './index.css';
import classnames from 'classnames';

export default function ArrowLink({
  title,
  link = '',
  hashLink = '',
  gtm = '',
  func = () => {},
  alt = '',
  newTab,
  imgStyle = {},
  icon,
  iconStyle = '',
  rel,
  ...rest
}) {
  const content = () => {
    return (
      <>
        <div>{title}</div>
        <img
          style={imgStyle}
          alt={alt}
          src={
            iconStyle
              ? require('../../../assets/images/icon/icon_arrow.svg')
              : require('../../../assets/images/icon/arrow.svg')
          }
        />
      </>
    );
  };

  return (
    <>
      {link && (
        <Link
          className={classnames({
            'pf-arrow-link--block': true,
            'pf-arrow-link--block-circle': iconStyle === 'circle',
            [gtm]: !!gtm,
          })}
          to={link}
          onClick={func()}
          isNewTab={newTab}
          {...rest}
          rel={rel}
        >
          {content()}
        </Link>
      )}
      {hashLink && (
        <HashLink
          className={`pf-arrow-link--block ${gtm}`}
          to={hashLink}
          onClick={func()}
          {...rest}
        >
          {content()}
        </HashLink>
      )}
      {!link && !hashLink && (
        <div
          className={`pf-arrow-link--block ${gtm}`}
          onClick={func()}
          {...rest}
        >
          {content()}
        </div>
      )}
    </>
  );
}
