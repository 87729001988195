import termsyoucamaichat from './terms-you-cam-ai-chat/routes';
import termsyoucamonline from './terms-you-cam-online/routes';
import termsyoucamcut from './terms-you-cam-cut/routes';
import termsyoucamnail from './terms-you-cam-nail/routes';
import termsyoucamvideo from './terms-you-cam-video/routes';
import termsyoucamenhance from './terms-you-cam-enhance/routes';
import termsyoucamai from './terms-you-cam-ai/routes';
import termsyoucamperfect from './terms-you-cam-perfect/routes';
import termsyoucammakeup from './terms-you-cam-makeup/routes';
import termsyoucam from './terms-you-cam/routes';

export default [
  termsyoucam,
  termsyoucammakeup,
  termsyoucamperfect,
  termsyoucamai,
  termsyoucamenhance,
  termsyoucamvideo,
  termsyoucamnail,
  termsyoucamcut,
  termsyoucamonline,
  termsyoucamaichat,
];
