import React from 'react';
import Link from '../../../components/link';
import {Trans} from 'react-i18next';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import webUtils from '../../../utils/webUtils';
import DemoStoreCard from '../header/component/demostorecard';
import headerUtils from '../../../utils/headerUtils';
import './Menus.css';

export default function Menus({onClick}) {
  const {location} = useReactRouter();

  return (
    <div className="pf-subheader-menu" style={{width: '100vw'}}>
      <div className="pf-subheader-menu__left">
        {webUtils.isMobile() ? (
          <div className="mobile-business-menu__title">
            <Trans i18nKey="business:p.b.s.e.s.menu.services">Services</Trans>
          </div>
        ) : (
          <div>
            <div className="pf-subheader-pop-menu-title">
              <Trans i18nKey="business:p.b.s.e.s.menu.enterprise.services">
                Enterprise Services
              </Trans>
            </div>
            <div className="pf-subheader-pop-menu-desc">
              <Trans i18nKey="business:p.b.s.e.s.menu.enterprise.services.desc">
                Our most powerful AI & AR beauty, fashion, and skin tech
                solutions for larger & multi-nation brands with complex
                requirements and maximum scale
              </Trans>
            </div>
          </div>
        )}
        <div style={{display: webUtils.isMobile() ? 'block' : 'flex'}}>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.makeup">Makeup</Trans>
              }
              items={headerUtils.makeupProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.nail.color">
                  Nail
                </Trans>
              }
              items={headerUtils.nailProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.eyewear">
                  Eyewear
                </Trans>
              }
              items={headerUtils.eyewearProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.accessories">
                  Accessories
                </Trans>
              }
              items={headerUtils.accessoriesProducts}
              onClick={onClick}
              location={location}
            />
          </div>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.face.diagnostic">
                  Face Diagnostic
                </Trans>
              }
              items={headerUtils.faceDiaProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.hair.color">
                  Hair
                </Trans>
              }
              items={headerUtils.hairProducts}
              onClick={onClick}
              location={location}
            />
          </div>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.jewelry">
                  Jewelry and Watches
                </Trans>
              }
              items={headerUtils.jewelryWatchesProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.beard.dye">
                  Men's grooming
                </Trans>
              }
              items={headerUtils.bearddyeProducts}
              onClick={onClick}
              location={location}
            />
            <Link
              to="/business/solutions/enterprise"
              className="pf-subheader-menu__viewall"
              onClick={onClick}
            >
              <Trans i18nKey="business:p.b.s.e.s.view.all">
                View all products
              </Trans>
            </Link>
            <Link
              to="/business/contact-us/sales"
              className="pf-subheader-menu__contact"
              onClick={onClick}
            >
              <Trans i18nKey="business:c.l.f.menudefinitions.contact.sales">
                Contact Sales
              </Trans>
            </Link>
          </div>
        </div>
      </div>
      <div className="pf-subheader-menu__right">
        <DemoStoreCard />
      </div>
    </div>
  );
}

const LinkGroup = ({groupName, items, onClick, location}) => {
  const isActive = paths => webUtils.isSublink(location.pathname, paths);
  return (
    <>
      <div className="pf-subheader-pop-menu__subtitle">{groupName}</div>
      {items.map(item => (
        <Link
          to={item.link}
          onClick={onClick}
          className={classnames({
            'pf-subheader-menu__link--active': isActive(item.link),
            'pf-subheader-menu__link': true,
          })}
          key={item.link}
        >
          {item.text}
        </Link>
      ))}
    </>
  );
};
