import React, {useContext, useEffect, useState} from 'react';
import _isEmpty from 'lodash/isEmpty';
import {Trans, useTranslation} from 'react-i18next';
import Container from '../../../../components/layout/container';
import SignInCard from '../components/SignInCard';
import './index.css';
import {AuthContext} from '../../../../components/auth';
import _get from 'lodash/get';
import webUtils from '../../../../utils/webUtils';
import _isNull from 'lodash/isNull';
import headerUtils from '../../../../utils/headerUtils';

export default function UserHome() {
  const {i18n} = useTranslation();
  const {accountSummary, isLoaded} = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);
  const [inActiveSolutionType, setInActiveSolutionType] = useState([]);
  const defaultData = {
    isTrial: false,
    solutionType: '',
    isOverdue: false,
    isOwner: false,
  };
  const solutionTypeOrder = [
    'SKIN_ANALYSIS',
    'AESTHETIC_SIMULATION',
    'MAKEUP',
    'EYEWEAR',
  ];
  function addOrderAndSort(items, sortedOrder) {
    const orderMap = sortedOrder.reduce((acc, type, index) => {
      acc[type] = index + 1;
      return acc;
    }, {});

    return items
      .map(item => ({
        ...item,
        order: orderMap[item.solutionType] || 99,
      }))
      .sort((a, b) => a.order - b.order);
  }

  useEffect(() => {
    if (!isLoaded) return;
    if (_isNull(accountSummary)) {
      window.location.href = `${webUtils.getLangPath(
        i18n.language
      )}/business/user/sign-in`;
      return;
    }
    const subscriptions = _get(accountSummary, 'subscriptions', []);
    const relatedCustomers = _get(accountSummary, 'relatedCustomers', []);
    const foundCustomer = relatedCustomers.find(
      s => s.email === _get(accountSummary, 'account.email')
    );
    const signInId = _get(foundCustomer, 'customerId');
    const isEnterprise =
      _get(
        subscriptions.find(s => s.customerId === signInId),
        'subscriptionType'
      ) === 'ENTERPRISE';
    const allSolutionTypes = [
      ...headerUtils.onlineService.map(s => s.solutionType),
    ];

    const updatedCustomers = subscriptions.map(s => {
      const index = allSolutionTypes.indexOf(s.solutionType);
      if (index !== -1) {
        allSolutionTypes.splice(index, 1);
      }

      const isOwner = relatedCustomers.some(
        customer =>
          customer.customerId === s.customerId &&
          customer.email === _get(accountSummary, 'account.email')
      );
      const foundCustomer = relatedCustomers.find(
        customer => customer.customerId === s.customerId
      );
      const customerName = foundCustomer ? foundCustomer.customerName : '';

      return {
        ...s,
        isOwner,
        customerName,
        isTrial: true,
      };
    });
    const reorderCustomers = addOrderAndSort(
      updatedCustomers,
      solutionTypeOrder
    );
    const inactiveSolutionTypes = allSolutionTypes.map(solutionType => ({
      ...defaultData,
      solutionType,
    }));
    const overdueCustomers = updatedCustomers.filter(
      customer => customer.isOverdue
    );
    const reorderInactiveCustomers = addOrderAndSort(
      isEnterprise
        ? [...overdueCustomers]
        : [...inactiveSolutionTypes, ...overdueCustomers],
      solutionTypeOrder
    );
    if (webUtils.isCnDomain()) {
      setCustomers(
        updatedCustomers.filter(s => s.solutionType !== 'AESTHETIC_SIMULATION')
      );
      setInActiveSolutionType(
        reorderInactiveCustomers.filter(
          s => s.solutionType !== 'AESTHETIC_SIMULATION'
        )
      );
      return;
    }
    setCustomers(reorderCustomers);
    setInActiveSolutionType(reorderInactiveCustomers);
  }, [accountSummary, isLoaded]);
  return (
    <div className="sign-in--solutionType-wrapper">
      <Container mobileType={true}>
        <div>
          <div className="sign-in--solutionType-title">
            <Trans i18nKey="business:p.b.u.entrance.function.type.title">
              Select service to enter corresponding backend console
            </Trans>
          </div>
          <div className="sign-in--solutionType-desc">
            <Trans i18nKey="business:p.b.u.entrance.function.type.desc">
              Please enter to your Console to manage your Products
            </Trans>
          </div>
        </div>
        <div className="line-container">
          <div className="line" />
          <div className="text">
            <Trans i18nKey="business:p.b.u.entrance.service.in.active">
              SERVICE IN ACTIVE
            </Trans>
          </div>
          <div className="line" />
        </div>
        <div>
          {!_isEmpty(customers) &&
            !_isEmpty(customers.filter(s => !s.isOverdue)) &&
            customers
              .filter(s => !s.isOverdue)
              .map(s => <SignInCard data={s} />)}
        </div>
        {!_isEmpty(inActiveSolutionType) && (
          <div>
            <div className="line-container">
              <div className="line" />
              <div className="text">
                <Trans i18nKey="business:p.b.u.entrance.service.not.active.currently">
                  NOT ACTIVE CURRENTLY
                </Trans>
              </div>
              <div className="line" />
            </div>
            <div>
              {inActiveSolutionType.map(s => (
                <SignInCard data={s} />
              ))}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
